import React from 'react';
import styles from './Banners.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';

const Banners = ({ primary }) => {
    const { title, description, image } = primary;

    return (
        <div className={styles.Banners}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <RichText render={title.richText} />
                    </div>

                    <div className={styles.descr}>
                        <RichText render={description.richText} />
                    </div>
                </div>
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={image?.url} />
                </div>
            </div>
        </div>
    );
};

Banners.propTypes = {
    primary: object,
    items: array,
};

export default Banners;
