import React from 'react';
import style from './Works.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';


const Works = ({ primary, items }) => {
  console.log(items, 'items');

  const { title } = primary;
  // const params = {
  //   slidesPerView: 'auto',
  //   spaceBetween: 16,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'progressbar',
  //   },
  //   breakpoints: {
  //     768: {
  //       spaceBetween: 32,
  //     },
  //     1024: {
  //       spaceBetween: 64,
  //     },
  //   },
  // };

  // const listItem = items.map((item) => {
  //   const key = item.name.text;
  //   return (
  //     <div className={style.slide} key={key}>
  //       <Item {...item} />
  //     </div>
  //   );
  // });

  return (
    <div className={style.works}>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.title}>
            <RichText render={title.richText} />
          </div>
          {/* <div className={style.description}>
            <RichText render={description.richText} />
          </div> */}
        </div>

        {/* <div className={style.list}>{listItem}</div> */}
        <div className={style.imageContainer}>
          <div className={style.ColumnOne}>
            {items.slice(0, 2)?.map((item) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={style.SubContainer}>
                    <img className={style.image} src={item?.screenshot?.url} />
                    <div className={style.tag}>
                      {item.tag.text}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className={style.ColumnTwo}>
            {items.slice(2, 5)?.map((item) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={style.SubContainer}>
                    <img className={style.image} src={item?.screenshot?.url} />
                    <div className={style.tag}>
                      {item.tag.text}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className={style.ColumnThree}>
            {items.slice(5, 7)?.map((item) => {
              return (
                <a
                  href={item?.link?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={style.SubContainer}>
                    <img className={style.image} src={item?.screenshot?.url} />
                    <div className={style.tag}>
                      {item.tag.text}
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

Works.propTypes = {
  primary: object,
  items: array,
};

export default Works;
