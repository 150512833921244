import React from 'react';
import styles from './Compliance.module.scss';
import Item from '@components/Works/components/Item';
import Swiper from 'react-id-swiper';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';

const Compliance = ({ primary, items }) => {
    const {
        title,
        description,
        mainimg
    } = primary;

    return (
        <div className={styles.compliance}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <RichText render={title.richText} />
                    </div>
                    <div className={styles.countries}>
                        {items?.map((imag) => {
                            return (
                                <img className={styles.image} src={imag.countryimg.url} />
                            )
                        })}
                    </div>
                    <div className={styles.descr}><RichText render={description.richText} /></div>
                </div>
                <div className={styles.imageWrapper}>
                    <img className={styles.image} src={mainimg?.url} />
                </div>
            </div>

        </div>
    );
};

Compliance.propTypes = {
    primary: object,
    items: array,
};

export default Compliance;
