import React from 'react';
import styles from './DifferentFeatures.module.scss';
import { RichText } from 'prismic-reactjs';
import { object, array } from 'prop-types';

const DifferentFeatures = ({ primary, items }) => {
    const { title } = primary;

    // const { image, description, subtitle } = items;

    return (
        <div className={styles.differentFeatures}>
            <div className={styles.container}>
                <div className={styles.title}>
                    <RichText render={title.richText} />
                </div>
                <div className={styles.featureType}>
                    {items?.map((item) => {
                        return (
                            <div className={styles.feature}>
                                <img src={`${item.image.url}`} />
                                <div className={styles.subTitle}><RichText render={item.subtitle.richText} /></div>
                                <div className={styles.descr}><RichText render={item.description.richText} /></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

DifferentFeatures.propTypes = {
    primary: object,
    items: array,
};

export default DifferentFeatures;
